import { createColor } from '@/utils/color'
import toSorted from 'array.prototype.tosorted'

toSorted.shim()

export function taskShowTimer(taskSource) {
  const status = getTaskStatus(taskSource.memberStatus)
  return (
    !!(
      taskSource.options.unpublished_at ||
      (taskSource.memberStatus && taskSource.memberStatus.remaining_time)
    ) && status !== 'completed'
  )
}

export function getTaskStatus(memberStatus) {
  if (!memberStatus) {
    return 'new'
  }

  if (memberStatus.unlocked_at) {
    return memberStatus.completed_at ? 'completed' : 'progress'
  }

  return 'paused'
}

export function getTaskTimeout(taskSource) {
  const deactivatedAt = taskSource.options.unpublished_at
  const endUnlockAt = taskSource.options.unlocking_deadline

  if (taskSource.memberStatus) {
    const remainingTime = taskSource.memberStatus.remaining_time
    if (deactivatedAt && remainingTime) {
      return Math.min(deactivatedAt, remainingTime)
    } else if (remainingTime) {
      return remainingTime
    } else {
      return deactivatedAt
    }
  }

  return endUnlockAt || deactivatedAt
}

export function getTaskProgress(memberStatus) {
  return memberStatus?.progress || 0
}

export function getTaskStyleVars(taskSource) {
  return {
    '--style-bg': createColor(
      taskSource.content.style?.background_color,
      'var(--t-app-l2-bg)'
    ),
    '--style-text': createColor(
      taskSource.content.style?.text_color,
      '#ffffff'
    ),
    '--style-progress-bg': createColor(
      taskSource.content.style?.progress_background_color,
      '#ffffff'
    ),
    '--style-progress-fill': createColor(
      taskSource.content.style?.progress_color,
      'linear-gradient(90deg, #753BBD 0%, #00C7B1 100%)'
    ),
    '--style-border': taskSource.content.style?.background_color
      ? '0 none'
      : '1px solid rgba(255, 255, 255, 0.06)'
  }
}

export function getSortedRewards(rewards) {
  return rewards.toSorted((a, b) => {
    if (a.resource.source?.id === 'coin') {
      return -1
    }
    if (b.resource.source?.id === 'coin') {
      return 1
    }
    return 0
  })
}
